@import "../../../../../../src/styles/settings/variables";
@import "../../../../../styles/utilities/mixins";
@import "../../../../../styles/utilities/animation";

.Modal {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

 

  &__modalContent {
    position: absolute;
    margin: 0 auto;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

  }
  
  &__header{
      position: absolute;
      right: 0;
      z-index: 9;

  }
  &__btnclose{
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      
      &:hover{
          opacity: 0.6;
      }
  }
  &__title{
      color:$primary-color;
  }
  &__actionMessage{
      text-align: center;
  }
  &__actionTitle{
      color:$primary-color;
  }

}

.lightbox{
    background: $secondary-color;
    width: 100%;
    margin: 10% auto 0;
    padding: 45px 10px;

    @media screen and(min-width:$screen-md) {
        padding: 25px;
        max-width: 700px;
    }

    @media screen and(min-width:$screen-lg){
        padding: 50px;
        max-width: 800px;
    }

    &__formGroupAdd{
        margin-left: 22px;
        width: 72px;
    }

    &__formGroup{
        border-bottom: 2px solid $tertiary-color;        
        margin-bottom: 25px;
        padding-bottom: 40px;



        &_add{
            margin-bottom: 5px;
        }

        &Input{
            border: none;
            text-transform: uppercase;
            // width: 76%;
            width: 85%;
            padding: 10px;
            font-family: $font-TradeGothic;
            font-weight: $weight-bold;
            font-size: $font-size-h3 - .5;
            color: $tertiary-color;
            background: $secondary-color;

            border: 2px solid $primary-color;
            position: relative;
            border-radius: 5px;

            @media screen and(min-width:$screen-lg){
                font-size: $font-size-h3;
                // width: 86%;
            }

            ::placeholder{
                color: $primary-color;
            }

            &:focus{
                outline-color: $quaternary-color;
            }

        }


        &Create, &Close, &Add{
            // border: none;
            // border: 2px solid;
            // margin-bottom: 5px;
            // font-size: $font-size-h3 - 1;
            // font-family:$font-TradeGothic;
            // text-transform: uppercase;
            // padding: 5px 3px 0;
            // min-width: 80px;
            // position: relative;
            // top:-5px;
            //  -webkit-transition: all 0.5s ease-out;
            //  -moz-transition: all 0.5s ease-out;
            //  -ms-transition: all 0.5s ease-out;
            //  -o-transition: all 0.5s ease-out;
            //  transition: all 0.5s ease-out;

             &:hover {
                 background: $quaternary-color;
                 border: 2px solid $quaternary-color;
                 color: $secondary-color;
             }

             @media screen and(min-width:$screen-lg) {
                  top:0;
             }
        }

        &Create{
            top:-10px;
        }


    }
    &__formGroupCloseContainer{
        text-align: right;
        margin-top: 30px;
    }

    &__formGroupOption{
        font-family: $font-TradeGothic;
    }

   &__dropdownContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0;
      position: relative;
      padding-bottom: 7px;

       label {
            color:lighten($tertiary-color, 22%);
            width: 86%;
            border: 2px solid $primary-color;
            position: relative;
            border-radius: 5px;

            @media screen and(min-width:$screen-lg){
                //  width: 86%;
            }
       }
   }

   &__dropdown {
       padding: 10px;
       cursor: pointer;
       background: $secondary-color;
       color:$primary-color;
       font-family: $font-TradeGothic;
       font-weight: $weight-bold;
       font-size: $font-size-h3 -  .5;
       border: none;
       text-transform: uppercase;

       @media screen and(min-width:$screen-lg){
           font-size: $font-size-h3;
       }
   }
   
    &__dropdownMenu {
        display: none;
        flex-direction: column;
        margin-top: 8px;
        width: 86%;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: $secondary-color;
        transform-origin: top left;
        position: absolute;
        top: 60px;
        color: $primary-color;
        font-size: $font-size-h3;
        text-align: left;
        box-shadow: none;

        @media screen and(min-width:$screen-lg){
            // width: 86%;
        }

        span{
            padding: 10px;
            flex-grow: 1;
            width: 100%;
            box-sizing: border-box;
            font-family: $font-TradeGothic;
            font-size: 2.5rem;
            cursor: pointer;
            transition: background 0.3s ease;
            &:last-child {
                border: none;
            }
            &:hover {
                background: $quaternary-color;
                color: $secondary-color;
            }
        }
   
    }

    &__dropdownLabelFocus{
        border: 2px solid $quaternary-color!important;
    }

    &__openDropdown:checked+&__dropdownMenu {
        display: flex;
        animation: openDropDown 0.4s ease;

    }

    &__openedDrDown {
       @include flex;
       box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
    }

    &__dropdownDisable{
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
            &:hover {
                background: inherit;
            }
    }

    &__note{
        font-size: 1.5rem !important;
        cursor: default;
        &:hover {
            background: transparent!important;
            color: $primary-color!important;
            cursor: default;
        }
    }

    &__icon{
        height: 30px;
        width: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid $primary-color;
        border-radius: 100%;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:hover{
            border: 2px solid $quaternary-color;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }

        &:focus{
            outline: none;
        }

    }

    &__arrow{
        height: 20px;
        width: 40px;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 30px;
        top: 10px;

         @media screen and(min-width:$screen-lg) {
             top: 13px;
         }
    }

    &__arrowOpen{
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    &__add{
        position: absolute;
        top: 8px;
        right: 10px;

        @media screen and(min-width:$screen-md) {
            right: 50px;
        }
       
        @media screen and(min-width:$screen-lg) {
            height: 50px;
            width: 50px;
            top: 2px;
            right: 25px;
        }
    }

    &__create{
        position: relative;
        right: 10px;
        top: -10px;

        @media screen and(min-width:$screen-md) {
            right: 50px;
        }
        @media screen and(min-width:$screen-lg) {
            height: 50px;
            width: 50px;
            right: 25px;
            top: -5px;
        }
    }

    &__close{
        position: absolute;
        top: 5px;
        right: 0;
    }


    
}

