@import "../../../../../../src/styles/settings/variables";
@import "../../../../../styles/utilities/mixins";
@import '../../../../../styles/utilities/animation';

.lightboxdetail{

  height: 100%;
  max-width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-auto-rows: 375px;
  grid-auto-flow: dense;



  &__container{
    height: 100%;
    padding-top: 30px;
  }

  &__header{
   margin-left: 0;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   padding-left: 2rem;

   @media screen and(min-width:$screen-lg){
     padding-left: 4rem;
   }
  }
  
  &__thumbnail{
    position: relative;
  }
  
  &__deleteBtn{
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  
  &__img{
    // width: 462px;
    // height: 376px;
    width: 100%;
    // height: 100%;
    height: 80%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__videoPlayIcon{
   position: absolute;
   -webkit-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
   transform: translateX(-50%);
   top: 0;
   left: 50%;
   opacity: .7;
   max-width: 100%;
   max-height: 100%;
   padding-top: 20%;
   padding-bottom: 20%;
  }

  &__printContainer{
    @include flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom:10px
  }

  &__print {
  
    &:after {
      content: '';
      display: block;
      border-bottom: solid 3px $secondary-color;
    }

    &:hover:after {
      -webkit-animation: borderBottomMove 1590ms infinite;
      /* Safari 4+ */
      -moz-animation: borderBottomMove 1590ms infinite;
      /* Fx 5+ */
      -o-animation: borderBottomMove 1590ms infinite;
      /* Opera 12+ */
      animation: borderBottomMove 1590ms infinite;
      /* IE 10+, Fx 29+ */
    }

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &__img{
    width: 100%;
    // height: 100%;
    height: 80%;
    background-size: cover;
    background-repeat: no-repeat;

    &:after{
      // content: none;
      border-bottom: none;
    }
   }
   &__imgTitle{
    position: absolute;
    top: calc(100% - 40px);

  }
}

.lightboxdetailcontainer{
  max-width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  // grid-auto-rows: 230px;
  // grid-auto-rows: 190px;
  grid-auto-rows: 240px;
  grid-auto-flow: dense;
  // margin-right: 2rem;
  margin-left: 0;
  float: none;
  padding-left: 0;

   

   @media screen and(min-width:$screen-lg) {
     padding-left: 3rem;
   }

   &__img, video{
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
   }

  

  // &:hover {
  //     img {
  //         // opacity: 0.28;
  //     }
  //     video {
  //         // opacity: 0.28;
  //     }
  // }
}

.mediaAssetItem {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  background: rgba(0, 0, 0, .2);
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  // grid-column: span 2;
  grid-column: span 1;

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    // &__img{
    //       opacity: 1;
    //   }
    //   video {
    //       opacity: 1;
    //   }
  }

  
}

.mediaAssetPhoto {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  transition: all .5s;
}