@import "../../../styles/settings/variables";
@import "../../../styles/utilities/mixins";


.User {
    padding-top: 9rem;
    padding-left: 1rem;
    @media screen and(min-width:$screen-lg){
        // padding-left: 0;
        padding-left: 2rem;
        // padding-right: 2rem;
        padding-right: 15px;
    }

    &__row{
        min-width: 100%;
    }
    &__infoData{
        @media screen and(min-width:$screen-lg){
            padding-left: 1rem;
        }
    }

    &__logo_container{
        padding-top: 40px;
        margin-bottom: 1.5rem;

        &:before {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background: $secondary-color;
            margin-bottom: 1.5rem;
        }

        &:after {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background: $secondary-color;
            margin-top: 0.8rem;
        }
    }

    &__addLightbox{
        position: fixed;
        background: white;
        z-index: 2;
        top: 50%;
        right: 11px;
        padding: 24px;
        font-size: 12pt;
        font-family: TradeGothic;
        text-transform: uppercase;
        &__checkbox{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }


    &__lightbhoxBtn{
        position: absolute;
        bottom: 4px;
        right: 4px;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &_video{
            z-index: 100;
        }

        &:hover img{
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            border: 1px solid $primary-color;
        }
    }

    &__lightbhoxBtnIcon{
        width: 30px;
        height: 30px;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        border: 1px solid transparent;
        border-radius: 100%;
    }

    &__wrapper {
        padding: 0;
        display: block;
        margin-top: 3rem;

        a{
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -ms-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;

            &:after{
                content: none;
            }

            &:hover{
                color: $quaternary-color;
            }
        }
        

        @media screen and(min-width:$screen-lg) {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            // padding-left: 3rem;
        }
    }

    &__header {
        padding: 2rem 0;
        @media screen and(min-width:$screen-lg){
            // padding: 0 0 0 3rem;
            padding: 0;
        }
    }

    &__title {
        text-transform: uppercase;
        opacity: 0.5;

        &:after {
            content: '';
            display: block;
            width: 65px;
            height: 4px;
            background: $secondary-color;
        }
    }
    &__name {
        text-transform: uppercase;
        line-height: 1;
        font-size: $font-size-h3 +  2;

        @media screen and(min-width:$screen-lg){
            font-size: $font-size-h3 + 5;
        }

        &:after {
            content: '';
            display: block;
            width: 135px;
            height: 7px;
            background: $secondary-color;
        }
    }

    &__description {
        padding-top: 3rem;
        max-width: 90%;
        color: rgba(194, 194,194, 0.65);
        white-space: pre-wrap;
        p {
            padding-bottom: 1.5rem;
            line-height: $line-height-base + .1;
        }

    }

    &__website {
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        margin-top: 3rem;

        &:before {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background: $secondary-color;
            margin-bottom: 1.5rem;
        }

        &:after {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background: $secondary-color;
            margin-top: 0.8rem;
        }
    }

    &__infoContainer {
        text-transform: uppercase;
        margin-bottom: 1.5rem;
     
    }

    &__agent {
        &:after {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background: $secondary-color;
            margin-top: 0.8rem;
        }
    }

    &__infoTitle {
        opacity: 0.65;
    }


    &__photoContainer {
        display: block;
    }

    &__photoContainerGrid {

        max-width: 100%;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        // grid-auto-rows: 230px;
        grid-auto-rows: auto;
        grid-auto-flow: dense;
       
        margin-left: 0;
        float: none;
    }

    &__LinkContainer{
        padding-left: 1.5rem;
        @media screen and(min-width:$screen-lg){
            padding-left: 4rem;
        }
    }

    &__Link {
        text-transform: uppercase;
        font-weight: $weight-normal;
        line-height: $line-height-t0;
        padding: 0;

        &:after {
            content: none;
        }


    }

    &__LinkCopy {
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:hover,
        &:focus {
            color: $quaternary-color;
        }
    }

    &__btnViewAll{
        margin-top: 3rem;
        border:2px solid $secondary-color;
        margin-bottom: 50px;

        &:hover{
            border:2px solid $quaternary-color;
        }


        &__win{
            padding:15px 35px;
        }

    }

    &__categoryName{
        padding: 15px 0 10px 0;
        grid-column-start: 1;
        grid-column-end: 4;
        &:first-child{
            padding-top: 0;
        }
    }

}

