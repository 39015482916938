@import "../../../src/styles/settings/variables";
@import "../../styles/utilities/mixins";

$listItemNudgeLg:29px;
$listItemNudgeMd:18px;
$listItemNudgeSm:5px;

.Categories {
    padding-left: 1rem;
    padding-top: 5.5rem;

    @media screen and(min-width:$screen-lg){
        padding-left: 0;
        padding-top: 8rem;
    }

    &__wrap {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        padding-left: 0;

        @media screen and(min-width:$screen-md){
            padding-top: $listItemNudgeSm;
        }

        @media screen and(min-width:$screen-md){
            padding-top: $listItemNudgeMd;
        }

        @media screen and(min-width:$screen-lg){
            padding-top: $listItemNudgeLg;
        }

    }

    &__listContainer {
        padding: 1.5rem 2rem 1.5rem 0rem;
        overflow: visible;
        @media screen and(min-width:$screen-lg) {
            padding: 0 2rem 2rem 1.5rem;
        }
    }

    &__listItemLink {
        
        &:after {
            content: none;
        }

    }

    &__listItem {

        display: block;
        text-transform: uppercase;
        
        @media screen and(min-width:$screen-sm){
            margin-top: -$listItemNudgeSm;
        }

        @media screen and(min-width:$screen-md){
            margin-top: -$listItemNudgeMd;
        }

        @media screen and(min-width:$screen-lg){
            display: inline-block;
            margin-top: -$listItemNudgeLg;
        }

        h2{

            line-height: 1.1;
            font-size: 5.2rem;
            letter-spacing: 0.1rem;

            @media screen and(min-width:$screen-md){
                font-size: 8.6rem;
                line-height: auto;
                letter-spacing: -0.1rem;
            }

            @media screen and(min-width:$screen-lg){
                font-size: 11rem;
                line-height: auto;
            }

        }
    }

    &__catSvg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 40px;
        display: inline-block;
        margin-right: 0;

        -o-object-fit: cover;    
        object-fit: cover;

        @media screen and(min-width:$screen-md) {
            height: 65px;
        }

        @media screen and(min-width:$screen-lg) {
            height: 80px;
        }

        @media screen and(min-width:$screen-xl + 300) {
            height: 85px;
        }
    }

    &__slashIcon {
        position: relative;
        width: 44px;
        height: 40px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 1;
        margin: 0;
        overflow: hidden;

        @media screen and(min-width:$screen-md) {
            height: 65px;
            width: 60px;
        }

        @media screen and(min-width:$screen-lg) {
            height: 80px;
            width: 62px;
        }
        svg{
            position: absolute;
            left: 50%;
            top:50%;
            vertical-align: initial;
            transform: translate(-50%, -50%);
            width: 170%;
            height: 170%;
            @media screen and(min-width:$screen-lg) {
                width: auto;
                height: auto;
            }
        }
    }

    &__preview {
        background-size: cover;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__test {
        background-size: cover;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: none;

    }

}