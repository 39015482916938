@import "../../../src/styles/settings/variables";
@import "../../styles/utilities/mixins";

.Hero {
    height: 100%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 999;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    justify-content: center;

    padding: 1.5rem;


    @media screen and(min-width:$screen-md) {

        padding: 4rem 1.5rem;
    }

    @media (min-width:$screen-md)and(min-height:$screen-md) {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }


    @media screen and(min-width:$screen-lg) {
        padding: 4rem;
    }


    @media screen and(min-width:$screen-xl) {
        min-height: $viewableArea;
    }

    &__titleImg {

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 40px;
        display: inline-block;
        // margin-bottom: 5px;
        margin-right: 0;


        @media screen and(min-width:$screen-md) {
            height: 65px;
        }

        @media screen and(min-width:$screen-lg) {
            height: 80px;
        }

        @media screen and(min-width:$screen-xl) {
            height: 100px;
            margin-bottom: 10px;
        }
    }

    &__titleImgContainer {
        margin-top: 5px;
        margin-bottom: 5px;
        display: inline-block;
    }

    &__titleImgContainerLink {
        // margin-top: 2px;
        // margin-bottom: 10px;
        margin-bottom: 5px;
        // margin-right: 10px;

        @media screen and(min-width:500px) {
            margin-bottom: 0;
        }

        @media screen and(min-width:$screen-md) {
            margin-left: 0;
        }
    }

    &__titleImgHoverLink {
        padding: 6px 3px 5px 5px;
        background: -webkit-gradient(linear, right top, left top, color-stop(50%, $primary-color), color-stop(50%, $quaternary-color)) right;
        background: -o-linear-gradient(right, $primary-color 50%, $quaternary-color 50%) right;
        background: linear-gradient(to left, $primary-color 50%, $quaternary-color 50%) right;
        background-size: 200%;
        -webkit-transition: .5s ease-out;
        -o-transition: .5s ease-out;
        transition: .5s ease-out;
        height: 52px;
        background-color: $primary-color;

        &:hover {
            background-position: left;
        }

        @media screen and(min-width:$screen-md) {
            height: 76px;
        }

        @media screen and(min-width:$screen-lg) {
            height: 92px;
        }

        @media screen and(min-width:$screen-xl) {
            height: 111px;
        }


    }

    &__titleImgHoverLinkDirector {
        padding: 7px 8px;
        height: 54px;

        @media screen and(min-width:$screen-md) {
            height: 79px;
        }

        @media screen and(min-width:$screen-lg) {
            height: 95px;
        }

        @media screen and(min-width:$screen-xl) {
            height: 114px;
        }
    }

    &__titleLink {
        &:after {
            content: none;
        }

        &__titleImg {
            margin-bottom: 0;
        }
    }

}