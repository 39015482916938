@import "../../../styles/settings/variables";
@import "../../../styles/utilities/mixins";

.QuickSearch {
    position: relative;
    min-width: 100%;
    position: absolute;
    right: 0;
    top: -55px;
    text-align: right;

    @media screen and(min-width:$screen-md) {
        top: 0;
        left: auto;
        min-width: 50%;
    }

    @media screen and(min-width:$screen-lg) {
        position: relative;
        text-align: left;
        top: 0;
    }

    &__btn {

        &:after {
            content: none;
        }

    }

    &__title {
        font-size: $font-size-subtitle + 1.5rem;
        text-transform: uppercase;
        line-height: $line-height-t0 + .25;
        margin-bottom: 4rem;
        position: relative;

        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:after {
            margin-top: 0;
            position: absolute;
            content: "";
            background: $secondary-color;
            width: 100%;
            height: 4px;
            display: block;

            @media screen and(min-width:$screen-lg) {
                width: 40px;
                margin-top: 7px;
            }


        }


        @media screen and(min-width:$screen-lg) {
            font-size: $font-size-h5-a;
            // width: 210px;
            width: 100%;
        }

    }

    &__dropdownItem {
        padding-left: 0;
        padding-top: 1rem;

        @media screen and(min-width:$screen-lg) {
            // padding-top: 0;
            padding-left: 30%;
        }
    }

    &__onHover {
        width: 0;
        height: 30px;
        margin: 0;
        background: $red;
        display: inline-block;
        position: absolute;
        margin-top: 5px;
        transform-origin: center right;
        right: 70%;
        display: none;

        @media screen and(min-width:$screen-lg) {
            display: block;
        }

    }

    &__subCat {
        &:after {
            content: none;
        }

        display: inline-block;
        padding-left: 1rem;
    }

    &__subCatItem {
        text-transform: uppercase;
        line-height: 1.1;
    }


    &__dropdown {
        pointer-events: none;
        opacity: 0.0001;
        transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
        transform: scale(0.6) translateY(-20%);
        -webkit-transform: scale(0.6) translateY(-20%);
        background: $primary-color;
        position: absolute;
        display: inline-block;
        // left: 35px;
        top: 45px;
        // width: 80%;
        right: 0;
        border-radius: 5px;
        // z-index: 9999;
        z-index: 9;
        border: 1px solid $secondary-color;

        @media screen and(min-width:$screen-md) {
            border: 1px solid transparent;
        }

        @media screen and(min-width:$screen-lg) {
            width: 100%;
            top: 80px;
            left: 6px;
        }

        @media screen and(min-width:$screen-xl) {
            top: 75px;
        }

        &>li>a {
            padding: 0 1rem;
            width: 100%;
            transition: all 0.2s;

        }

    }


    &__activeDrop {
        opacity: 0.9999;
        transform: none;
        pointer-events: initial;
        -webkit-transform: none;
    }

}