@import "../../styles/settings/variables";
@import "../../styles/utilities/mixins";



.LoginPage {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: calc(100vh - 13rem);
        text-align: center;
        font-family: $font-proximanova-regular;

        &__logo {
                max-height: 100px;
                text-align: center;
        }

        &__form {

                margin: 50px 0px;

                &__forgotten_remember {
                        text-align: center;
                        margin: 0 auto;
                }

                &__remember {
                        margin-left: 18px;

                }

                &__remember_checkbox {
                        height: 30px;
                        width: 30px;
                        margin: 0.25rem 0rem 0rem -1.5rem;
                        
                }

                &__remember_label {
                        color: $white;
                        font-size: 1.9rem;
                        margin: 8px;

                }

                &__submit {
                        margin: auto;

                }

                &__forgotten {
                        color: $ltGrey !important;
                        font-family: $font-proximanova-regular;
                        font-size: 2.1rem;

                        &:after {
                                content: none;
                        }
                }
        }

        &__form_label {
                visibility: hidden;
        }

        &__form_input {
                border-radius: 30px;
                font-size: 1.7rem;
                padding: 1rem 1.5rem;
                outline: none;
                max-width: 450px;
                // margin-left: -2.25%;
                border: 2px solid $secondary-color;
                outline: 0;
                border: 2px solid white;
                outline: 0;
                -webkit-transition: all 0.5s ease-out;
                -moz-transition: all 0.5s ease-out;
                -ms-transition: all 0.5s ease-out;
                -o-transition: all 0.5s ease-out;
                transition: all 0.5s ease-out;

                &:focus {
                        border: 2px solid $quaternary-color;
                }

                &:required,
                &:invalid {
                        box-shadow: none;
                }

                &__invalid {
                        border-color: #dc3545;
                        padding-right: calc(1.5em + 0.75rem);
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                        background-repeat: no-repeat;
                        background-position: right calc(0.375em + 0.1875rem) center;
                        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                }
        }


        &__submit_button {
                background-color: $secondary-color;
                color: $tertiary-color;
                padding: 15px 60px;
                font-size: 1.5rem;

                -webkit-transition: all 0.5s ease-out;
                -moz-transition: all 0.5s ease-out;
                -ms-transition: all 0.5s ease-out;
                -o-transition: all 0.5s ease-out;
                transition: all 0.5s ease-out;
                border: none;

                &:hover {
                        background-color: $quaternary-color;
                        color: $secondary-color
                }
        }

        
        
}
