
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

@import "../../../../../src/styles/settings/variables";
@import "../../../../styles/utilities/mixins";


.PhotosCarousel{
    position: relative;
    height: 100vh;
    width: auto;
    margin: 0 auto;
    overflow: hidden;
    z-index: 8;
    &__photo{
        // display: block;
        // margin: 0 auto 0 auto;
        // width: 100%;

        max-height: 100vh;
        width: auto;
   
      @media screen and (min-width: 40em) {
        
        //   padding: 0 2em 0 2em;
        //   width: 66.6%;
      } 
    }

    &__lightboxDetails{
      position: absolute;
      bottom:0;
      right: 0;
      left:0;
      margin: 0 auto;
      color:$secondary-color;
      background: $primary-color;
      padding: 10px 20px;
      p{
       font-size: $font-size-h6 - 0.3;
      }
    }
}