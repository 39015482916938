// @import "~styles/settings/variables";
// @import "~styles/utilities/mixins";


@import "../../../src/styles/settings/variables";
@import "../../styles/utilities/mixins";


.Header {
    min-height: 10rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    background: $primary-color;

    &__userType{
        &--active{
            background: $red;
            padding-left: 5px;
            padding-right: 5px;
            pointer-events: none;
        }
    }

    &__fixingContainer {
        position: fixed;
        // padding-top: 1rem;
        background: $primary-color;
        z-index: 999;
        width: inherit;
    }

    &__left {
        @include inlineFlex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 2rem;

        // @media screen and(min-width:$screen-md) {
        //     padding: 2rem;
        // }
        @media screen and(min-width:$screen-lg) {
            padding: 2rem 3rem;
        }
    }

    &__right {

        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-flow: column;
        padding-right: 2rem;

        @media screen and(min-width:$screen-sm) {
            display: flex;
            align-items: flex-end;
        }

        @media screen and(min-width:$screen-md) {
        padding-right: 29px;
        }

        &__menu{
            > li {
                margin-left: 10px;
                a {
                    &:after {
                        border-bottom: solid 3px $secondary-color;
                    }
                    h6 {
                        @media screen and(max-width:$screen-sm) {
                            font-size: 1.3em;
                        }
                    }
                } 

            }           
        }
    }

    &__nav{
        margin-top: 25px;
        @media screen and(min-width:$screen-md) {
            margin-top: 0;
        }
    }

    &__squintLogo {
        // width: 95px;
        width: 75px;
        height: 60px;

        @include coverBackground;
        background-size: contain;
        margin-right: 2rem;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:hover {
            opacity: 0.6;
        }

        @media screen and(min-width:$screen-md) {
            width: 109px;
            height: 90px;
        }

        @media screen and(min-width:$screen-xl) {
            margin-right: 3.5rem;
        }
    }

    &__clientLogo {
        width: 100px;
        height: 52px;
        @include coverBackground;
        background-size: contain;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:hover {
            opacity: 0.6;
        }

        @media screen and(min-width:$screen-md) {
            width: 150px;
            height: 90px;
        }
    }

    &__rightEmail {
        padding-right: 2.5rem;

        

        @media screen and(min-width:$screen-md) {
            padding-right: 2rem;
        }

        @media screen and(min-width:$screen-lg) {
            padding-right: 3rem;
        }

        @media screen and(min-width:$screen-xl) {
            margin-top: 0;
        }
    }

}