// @import "../../../../src/styles/settings/variables";
// @import "../../../styles/utilities/mixins";

.Modal {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;

    &__modalContent {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        // top: 5%;
        margin: 0 auto;
        // width: 90%;

    }
    
    &__header{
        position: absolute;
        right: 0;
        z-index: 9;

        // @media screen and(min-width:$screen-xl) {
        //     right: -25px;
        // }
    }
    &__btnclose{
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        &:hover{
            opacity: 0.6;
        }
    }

}