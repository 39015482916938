@import "../../../../../../src/styles/settings/variables";
@import "../../../../../styles/utilities/mixins";
@import '../../../../../styles/utilities/animation';

.lightboxdetail{
  max-width: 100%;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  // grid-auto-rows: 190px;
  // grid-auto-rows: 220px;
  grid-auto-rows: 240px;
  
  &__wrapper{
    // padding-left: 1rem;

    // @media screen and(min-width:$screen-lg){
    //   padding-left: 3.5rem;
    // }
    padding: 0 6px;
    @media screen and(min-width:$screen-lg){
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__container {
    // padding-left: 1rem;
    // padding-top: 9rem;
    padding: 9rem 6px 0 6px;
    @media screen and(min-width:$screen-lg){
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

  
  }

  &__header{
      margin-left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      @media screen and(min-width:$screen-lg){
        // padding-left: 4rem;
        // padding-right: 4rem;
      }
  }
  
  &__thumbnail{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-column-span: 1;
    grid-column: span 1;
  }
  &__share{
    padding: 0;
    &:after {
      content: '';
      display: block;
      border-bottom: solid 3px $secondary-color;
    }

    &:hover:after {
       -webkit-animation: borderBottomMove 1590ms infinite;
         /* Safari 4+ */
          -moz-animation: borderBottomMove 1590ms infinite;
         /* Fx 5+ */
         -o-animation: borderBottomMove 1590ms infinite;
         /* Opera 12+ */
         animation: borderBottomMove 1590ms infinite;
         /* IE 10+, Fx 29+ */
    }

    &:hover{
       text-decoration: none;
         color: inherit;
    }
  }

  
  &__deleteBtn{
    position: absolute;
    bottom: 50px;
    right: 5px;
    padding: 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    height: 30px;
    width:30px;

    img, div{
      height: 30px;
      width:30px;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;

      &:hover {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  
    // &:hover{
    //  -webkit-transform: scale(1.1);
    //  -ms-transform: scale(1.1);
    //  transform: scale(1.1);
    // }
  }
  
  &__img{
    // width: 462px;
    // height: 376px;
    width: 100%;
    // height: 100%;
    height: 80%;
    background-size: cover;
    background-repeat: no-repeat;

    &:after{
      // content: none;
      border-bottom: none;
    }
  }

  &__videoPlayIcon{
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    opacity: .7;
    max-width: 100%;
    // max-height: 100%;
    max-height: 80%;
    padding-top: 20%;
    padding-bottom: 20%;
  }

  &__imgTitle{
    position: absolute;
    top: calc(100% - 40px);

  }
}

.lightboxdetailcontainer{
  max-width: 100%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  // grid-auto-rows: 230px;
  grid-auto-rows: 190px;
  grid-auto-flow: dense;
  // margin-right: 2rem;
  margin-left: 0;
  float: none;


  &:hover {
      img {
          opacity: 0.28;
      }
      video {
          opacity: 0.28;
      }
  }
}

.mediaAssetItem {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  background: rgba(0, 0, 0, .2);
  overflow: hidden;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  // grid-column: span 2;
  grid-column: span 1;

  &:hover {
      img {
          opacity: 1;
      }
      video {
          opacity: 1;
      }
  }

  
}

.mediaAssetPhoto {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  transition: all .5s;
}