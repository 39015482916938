@import "../../../../../../../src/styles/settings/variables";
@import "../../../../../../styles/utilities/mixins";

.Modal {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  

  &__modalContent {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      // top: 5%;
      margin: 0 auto;
      // width: 90%;

  }
  
  &__header{
      position: absolute;
      right: 0;
      z-index: 9;

  }
  &__btnclose{
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      &:hover{
          opacity: 0.6;
      }
  }

}

.lightbox{
    background: $secondary-color;
    margin: 10% auto 0;
    position: relative;
    width: 100%;
    padding: 45px 10px;

   @media screen and(min-width:$screen-md) {
       padding: 25px;
       max-width: 700px;
   }

   @media screen and(min-width:$screen-lg) {
       padding: 50px;
       max-width: 800px;
   }
    &__formGroupAdd{
        margin-left: 22px;
        width: 72px;
    }

    &__formGroup{
        // border-bottom: 1px solid $tertiary-color;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 25px;
        position: relative;

        &Input{
            border: none;
            width: 86%;
            padding: 10px;
            font-family: TradeGothic;
            font-weight: 600;
            font-size: $font-size-h3 - 0.5;
            background: $secondary-color;
            margin-top: 6px;
            ::placeholder{
                color: black;
            }

            &:focus{
                outline-color: $quaternary-color;
            }
        }

        &Create, &Close, &Add{
            border: none;
            border: 2px solid;
            margin-bottom: 5px;
            font-size: 2rem;
            font-family: TradeGothic;
            text-transform: uppercase;
            padding: 5px 3px 0;
            min-width: 80px;
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -ms-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;

            &:hover{
                background: $quaternary-color;
                border: 2px solid $quaternary-color;
                color:$secondary-color;
            }
        }
        // &__formGroupInput{
        //     width: 86%;
        // }
        


    }

    &__textSuccess{
        color: #28a745!important;
        margin-bottom: 11px;
        position: absolute;
    }
    &__icon {
        height: 30px;
        width: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        // border: 2px solid $primary-color;
        border-radius: 100%;
       

        &:focus {
            outline: none;
        }

    }



     &__copy {
        position: absolute;
        top: 8px;
        right: 10px;
        border: 2px solid $primary-color;
        border-radius:100% ;
        padding-top: 6px;
        padding-right: 2px;

        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

        &:hover {
            border: 2px solid $quaternary-color;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }


         @media screen and(min-width:$screen-md) {
             right: 50px;
         }

         @media screen and(min-width:$screen-lg) {
             height: 50px;
             width: 50px;
             top: 2px;
             right: 25px;
         }
     }

     &__copyIcon{
        background-size: contain;
        height: 30px;
        width: 28px;

        @media screen and(min-width:$screen-lg){
            height: 37px;
            width: 40px;
        }
     }


     &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        border: 2px solid $primary-color;
        border-radius:100%;
        padding: 0;

        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

           &:hover {
               border: 2px solid $quaternary-color;
               -webkit-transform: scale(1.1);
               -ms-transform: scale(1.1);
               transform: scale(1.1);
           }
     }
    
    
}

