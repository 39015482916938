//////////// ANIMATION BORDER BOTTOM LINK
@-webkit-keyframes borderBottomMove {
  0%   { transform: scaleX(1) }
  25% { transform: scaleX(0) }
  50%   { transform: scaleX(1) }
  75% { transform: scaleX(0) }
  100% { transform: scaleX(1) }
}
@-moz-keyframes borderBottomMove {
   0%   { transform: scaleX(1) }
  25% { transform: scaleX(0) }
  50%   { transform: scaleX(1) }
  75% { transform: scaleX(0) }
  100% { transform: scaleX(1) }
}
@-o-keyframes borderBottomMove {
    0%   { transform: scaleX(1) }
    25% { transform: scaleX(0) }
    50%   { transform: scaleX(1) }
    75% { transform: scaleX(0) }
    100% { transform: scaleX(1) }
}
@keyframes borderBottomMove {
    0%   { transform: scaleX(1) }
    25% { transform: scaleX(0) }
    50%   { transform: scaleX(1) }
    75% { transform: scaleX(0) }
    100% { transform: scaleX(1) }
}     


///ANIMATION DROPDOWN LIGHTBOX
@keyframes openDropDown {
  from {
    transform: rotateX(50deg);
  }

  to {
    transform: rotateX(0deg);
  }
}


/////ANIMATION FRAME COLOR PHOTO USER SELECTED
@-webkit-keyframes colorFramechange
{
  0%   {border:3px solid $quaternary-color;}
  50%   {border:3px solid $quaternary-color;}
  100% {border:3px solid $quaternary-color;}
}
@keyframes colorFramechange
{
  0%   {border:3px solid $quaternary-color;}
  50%   {border:3px solid $quaternary-color;}
  100% {border:3px solid $quaternary-color;}
}
