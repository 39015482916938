@import "../../../../styles/settings/variables";
@import "../../../../styles/utilities/mixins";

.lightboxes{
  padding-top:9rem;
  
  &__title{
    text-transform: uppercase;
     padding-top: 1rem;
    line-height: 1.1;
  }
  &__list{
    float: left;
    clear: left;
    @media screen and(min-width:$screen-lg){
      margin-left: 1rem;
    }
  }
  &__listItem{
     display:-webkit-box;
     display:-ms-flexbox;
     display:flex;
  }

  &__listBtn{
     -webkit-transition: all 0.5s ease-out;
     -moz-transition: all 0.5s ease-out;
     -ms-transition: all 0.5s ease-out;
     -o-transition: all 0.5s ease-out;
     transition: all 0.5s ease-out;

    &:hover{
      -webkit-transform:scale(1.1);
      -ms-transform:scale(1.1);
      transform:scale(1.1)
    }
  }
  &__link {
      text-decoration: none;  
      text-transform: uppercase;
      background: rgba(0, 0, 0, .2);
      overflow: hidden;
      padding: 0;
      display: flex;
      align-items: stretch;
      justify-content: center;
      // grid-column: span 2;
      grid-column: span 1;
    &:after {
        content: '';
        display: block;
        border-bottom: none;
    }
  
  }

  &__delete{
    width: 30px;
    height: 30px;
  }
}
