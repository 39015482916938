@import "../../../../../../src/styles/settings/variables";
@import "../../../../../styles/utilities/mixins";

.ModalConfirmation {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;


  &__modalContent {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      margin: 0 auto;

  }
  
  &__header{
      position: absolute;
      right: 0;
      z-index: 9;

  }
  &__btnclose{
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      &:hover{
          opacity: 0.6;
      }
  }

  &__title{
      color: $primary-color;
      opacity: 0.5;
      text-transform: uppercase;
      padding-bottom: 20px;
  }
  &__message{
      color: $primary-color;
      padding-bottom: 20px;
  }
  &__btnContainer{
    padding: 20px 0;
  }
  &__confirmBtn{
    color: $secondary-color;
    padding: 15px 60px;
    font-size: $font-size-h3 - 1.5;
    font-family: $secondary-font-family;
    margin: 0 10px;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border: 2px solid transparent;
  }

  &__cancelbtn{
    background-color:$ltGrey;

    &:hover {
        background-color: transparent;
        color:$primary-color;
        border: 2px solid $primary-color;
    }
  }
  &__deletebtn{
    background-color: $quaternary-color;

    &:hover {
        background-color: transparent;
        color: $primary-color;
        border: 2px solid $primary-color;
    }
  }

}

.lightbox{
    background: $secondary-color;
    margin: 10% auto 0;
    position: relative;
    width: 100%;
    padding: 45px 10px;
    color: $primary-color;
    text-align: center;

    @media screen and(min-width:$screen-md) {
        padding: 25px;
        max-width: 700px;
    }

    @media screen and(min-width:$screen-lg) {
        padding: 50px;
        max-width: 550px;
    }

    &__formGroupAdd{
        margin-left: 22px;
        width: 72px;
    }

    &__icon {
        height: 30px;
        width: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 100%;

        &:focus {
            outline: none;
        }

    }

     &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        border: 2px solid $primary-color;
        border-radius:100%;
        padding: 0;

        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;

           &:hover {
               border: 2px solid $quaternary-color;
               -webkit-transform: scale(1.1);
               -ms-transform: scale(1.1);
               transform: scale(1.1);
           }
     }
    
    
}

