@import "../../../../styles/settings/variables";
@import "../../../../styles/utilities/mixins";


.PhotoCollectionItem{
    &__photo {
        width: 100%;
        height: 100%;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .5s;

    }
    &__video_play_icon{
        position: absolute;
        transform: translateX(-50%);
        top: 0px;
        left: 50%;
        opacity: 0.7;
        max-width: 100%;
        max-height: 100%;
        padding-top: 20%;
        padding-bottom: 20%;
    }
    &__photoItem {
        position: relative;
        cursor: pointer;
        text-align: center;
        padding: 1rem 0;
        color: $secondary-color;
        font-size: 3rem;
        text-transform: uppercase;
        background: rgba(0, 0, 0, .2);
        overflow: hidden;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        grid-column: span 1;
        height: 190px;
    }
}