@import 'reset.scss';
@import 'svgs.scss';
@import '../utilities/utilities.scss';


body {
    background-color: $primary-color;
    color: $secondary-color;
}

/////////MAIN CONTAINER
.mainContainer {
    height: calc(100vh - 10rem);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/////////CUSTOM SCROLLBAR
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $ltGrey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: 10px;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

::-webkit-scrollbar-thumb:hover {
    background: lighten($secondary-color, 30%);
}

.divider {
    // border-bottom: 2px solid $secondary-color;
    // padding-top: 1.5rem;
    content: '';
    background: $secondary-color;
    width: 90px;
    height: 3px;

    @media screen and (min-width:$screen-lg) {
        height: 2px;
    }
}

.redText {
    color: $red;
}


////underline btns
.mainBtn {
    text-decoration: none;
    font-family: $secondary-font-family;
    color: $secondary-color;
    cursor: pointer;
    display: inline-block;

    &:after {
        content: '';
        display: block;
        border-bottom: solid 5px $secondary-color;
    }

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    &:hover:after {
        -webkit-animation: borderBottomMove 1590ms infinite;
        -moz-animation: borderBottomMove 1590ms infinite;
        -o-animation: borderBottomMove 1590ms infinite;
        animation: borderBottomMove 1590ms infinite;
    }
}

.fadeOut {
    margin-left: -100%;
    transition: all 3s;


}

.fadeIn {
    margin-left: 2.5%;
    transition: all 3s;

}


.active {
    cursor: pointer;
    opacity: 1;

    &:after {
        content: none;
    }

    h2 {
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
    }

    &:hover h2,
    &:focus h2 {
        color: $quaternary-color;
    }
}

.noActive {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;

    &:after {
        content: none;
    }
}

/////////// secondary style main buttons

.secondaryBtn {
    background-color: $primary-color;
   
    color: $secondary-color;
    padding: 15px 35px 8px 35px;
    font-size: $font-size-h7-a;
    font-family: $secondary-font-family;
    text-transform: uppercase;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border: none;
    border: 2px solid $quaternary-color;
    // font-size: 4rem;
    // font-family: TradeGothic;

    &:hover {
            background-color: $quaternary-color;
            color: $secondary-color
    }
}

//////////////////// change opacity photouser on click quick search
.addOpacity {
    opacity: 0.4;

}

.hideList {
    display: none;
}




/////// PREVIEW IMAGE ON HOVER
.previewImg {
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/////// ON HOVER FADING SIBLINGS
$listHoverColour:grey;

.siblingContainer,
.siblingCatH a,
.siblingCatH h2,
.siblingCatH path,
.siblingContainer,
.siblingCatH a~.slashCat {
    color: white;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.siblingCatH a:hover,
.siblingCatH a:hover~.slashCat:hover {
    h2{
        color: $listHoverColour;
    }
    path{
        fill: $listHoverColour;
    }
    color: $listHoverColour;
}

.siblingContainer:hover>.siblingCatH a,
.siblingContainer:hover>.siblingCatH a~.slashCat {
    h2{
        color: $listHoverColour;
    }
    path{
        fill: $listHoverColour;
    }
    color: $listHoverColour;
}

.siblingContainer:hover>.siblingCatH a:hover,
.siblingContainer:hover>.siblingCatH a:hover~.slashCat:hover {
    h2{
        color: white;
    }
    path{
        fill: white;
    }
    color: white;
}

///////// FADING BACKGROUND IMAGE LIST
.siblingIMGContainer,
.siblingImgH,
.siblingIMGContainer {
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.siblingImgH:hover {
    opacity: 0.5;
}

.siblingIMGContainer:hover>.siblingImgH {
    opacity: 0.5;
}

.siblingIMGContainer:hover>.siblingImgH:hover {
    opacity: 1;
}


/////// CAROUSEL SLIDE 
.slick-slider {
    height: 100vh;
}

.slick-track {
    right: 0;
    margin: 0 auto;
    width: auto;
    height: 100vh;


}

.slick-initialized .slick-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

}

.slick-slide img {
    margin: 0 auto;
    vertical-align: middle;
    display: inline-block;
    //     max-height: 100vh;
    //     -webkit-box-pack:center;
    // -ms-flex-pack:center;
    //         justify-content:center;
}

.slick-arrow {
    width: 45px;
    height: 45px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    z-index: 9999;
}

.slick-prev:before,
.slick-next:before {
    content: none;
}

.slick-next,
.slick-prev {
    // transform: translate(0,-50%)!important;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;

    &:hover {
        opacity: 0.6;
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    top: 51%;
    right: 0;
}

//////////checkbox login page

.custom-checkbox .custom-control-label::before {
    border-radius: 50%;

}

.custom-control-label {
    cursor: pointer;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.custom-control-label:hover {
    .custom-control-label::before {
        border: $quaternary-color solid 1px;
    }
}

.custom-control-label::before {
    position: absolute;
    top: 0;
    left: -2.5rem;
    display: block;
    width: 2rem;
    height: 2rem;
    pointer-events: none;
    content: "";
    background-color: transparent;
    border: $secondary-color solid 1px;
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.custom-control-input {
    position: absolute;
    left: 23px;
    z-index: 1;
    width: 13rem;
    height: 3rem;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $quaternary-color;
    background-color: $quaternary-color;
}

.custom-control-label::before {
    width: 2rem;
    height: 2rem;
}

.custom-control-label::after {
    position: absolute;
    top: 0;
    left: -2.5rem;
    display: block;
    width: 2rem;
    height: 2rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.help-block {
    padding: 1rem 0;
}

.text-danger {
    font-size: $font-size-base;
}

// .borderChecked {
//     border: 5px solid red;
// }

////////////////// checkbox lightbox photos 
.checkbox-container {
    // z-index: 99;
    // z-index: 2;
}

.checkbox-label {
    display: block;
    position: absolute;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    clear: both;
    bottom: 10px;
    right: 10px;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid $secondary-color;
    z-index: 2;
}


.checkbox-label input:checked~.checkbox-custom {
    background-color: $secondary-color;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid $secondary-color;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid $quaternary-color;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked~.checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid $quaternary-color;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid $secondary-color;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.checkbox-label input:checked~.checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


//////colored frame added on selected image

.imgSelected {

    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.2s linear;
    box-sizing: inherit;
    z-index: 1;


    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        transition: 0.2s linear;
        border: 3px solid $quaternary-color;
        animation: colorFramechange 1.4s linear;        
    }


}