//ADD CUSTOM FONT FACES HERE
@font-face {
    font-family: "TradeGothic";
    src: url(../fonts/TradeGothicLTStd-BdCn20.otf) format("opentype");
    font-display: swap
}
@font-face {
    font-family: "proximanova-regular";
    src: url(../fonts/proximanova-regular.otf) format("opentype");
    font-display: swap
}
@font-face {
    font-family: "proximanova-thin";
    src: url(../fonts/proximanova-thin.otf) format("opentype");
    font-display: swap
}
