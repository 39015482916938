#titleSvg_0 {
    display: none;
}

//welcome
#title1Svg_1 {
    // width: 235px;
    width: 188px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 311px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 347px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 440px;
    }

}

//to
#title1Svg_2 {
    // width: 63px;
    width: 50px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 80px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 89px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 115px;
    }
}

//squintbox
#title1Svg_3 {
    // width: 260px;
    width: 210px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 347px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 420px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 520px;
    }
}

//AN
#title2Svg_1 {
    width: 51px;
    // margin-bottom: 5px;
    margin-right: 10px;
    // margin-bottom: 15px;
    // margin-right: 25px;
    
    @media screen and(min-width:$screen-md) {
        width: 81px;
        height: 63px;
        margin-bottom: 6px;
        margin-right: 18px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 96px;
        height: 72px;
        margin-bottom: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 117px;
        height: 90px;
        margin-bottom: 15px;
    }
}

//have
#title2vg_2 {
    width: 118px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 158px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 170px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 230px;
    }
}

//exclusively
#title2Svg_3 {
    // width: 305px;
    width: 249px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 399px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 459px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 576px;
    }
}

//curated
#title2Svg_4 {
    // width: 207px;
    width: 170px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 289px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 318px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 420px;
    }
}

//gallery
#title3Svg_1 {
    width: 161px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 263px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 325px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 404px;
    }
}

//of
#title3Svg_2 {
    // width: 63px;
    width: 48px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 79px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 86px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 118px;
    }
}

//the
#title3Svg_3 {
    // width: 98px;
    width: 70px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 128px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 173px;
    }
}

//world
#title3Svg_4 {
    // width: 178px;
    width: 132px;
    margin-right: 0 !important;

    @media screen and(min-width:$screen-md) {
        width: 223px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 257px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 330px;
    }
}

//'s
#title3Svg_5 {
    // width: 47px;
    width: 35px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 58px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 76px;
    }
}

//most
#title3Svg_6 {
    // width: 132px;
    width: 100px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 175px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 187px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 240px;
    }

}

//inspiring
#title4Svg_1 {
    margin-left: 5px;
    width: 127px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 208px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 254px;
        margin-right: 10px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 319px;

    }
}

//photographers
#title4Svg_2 {
    // width: 374px;
    width: 307px;

    @media screen and(min-width:$screen-md) {
        width: 536px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 616px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 770px;
    }
}

//for
#title5Svg_1 {
    // width: 97px;
    width: 80px;
    margin-right: 5px;
    margin-left: 0;


    @media screen and(min-width:640px) {
        margin-left: 10px;
    }
    @media screen and(min-width:$screen-md) {
        width: 125px;
        margin-left: 0;
    
    }

    @media screen and(min-width:$screen-lg) {
        width: 153px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 192px;
        margin-right: 10px;
    }
    @media screen and(min-width:$screen-xl + 300) {
        margin-left: 10px;
    }
}
//photographers
#title5Svg_0{
    // width: 312px;
    width: 329px;
    // margin-bottom: 0;

    @media screen and(min-width:$screen-md) {
        width: 525px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 654px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 809px;
    }
    
}

//directors
#title5Svg_2 {

    width: 213px;
    // margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 352px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 432px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 535px;
        // margin-left: 10px;
    }
}

//right
#title5Svg_3 {
    // width: 139px;
    width: 112px;
    margin-right: 5px;

    @media screen and(min-width:$screen-md) {
        width: 187px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 223px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 270px;
    }

}

//here
.titleSvgLink_1 {
    padding: 0;
    // width: 127px;
    width: 102px;
    margin-left: 3px;

    @media screen and(min-width:$screen-md) {
        width: 154px;
    }

    @media screen and(min-width:$screen-lg) {
        width: 197px;
    }

    @media screen and(min-width:$screen-xl) {
        width: 240px;
    }
}

.titleSvgSpot_1 {
    height: 15px !important;
    width: 15px;

    @media screen and(min-width:$screen-lg) {
        height: 20px !important;
        width: 20px;
    }

    @media screen and(min-width:$screen-xl) {
        height: 40px !important;
        width: 40px;
    }
}