@import '../../styles/settings/variables';

.loader {
  border: 16px solid transparent;
  border-top: 16px solid $ltGrey;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
      transform: rotateZ(0);
  }

  100% {
      transform: rotateZ(360deg);
  }
}