@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

// Colours
$black: #000000;
$white: #FFFFFF;
$ltGrey:#C2C2C2;
$dkGrey:#474747;
$red:#ff0037;


$green:#39CC74;
$linkBlue:#2699FB;

// Colour Roles
$primary-color: $black;
$secondary-color: $white;
$tertiary-color: $dkGrey;
$quaternary-color: $red;


//Menu
$menuHeight: 130px;

//Viewport
$viewableArea: calc(100vh - #{$menuHeight});
$mobileViewableArea: calc(100vh - 51px);


// Screen Sizes
$screen-xl: map-get($grid-breakpoints, xl); // ~1200px
$screen-lg: map-get($grid-breakpoints, lg); // ~992px
$screen-md: map-get($grid-breakpoints, md); // ~768px
$screen-sm: map-get($grid-breakpoints, sm); // ~576px
$screen-xs: map-get($grid-breakpoints, xs); // 0

// Screen Sizes Max
$screen-xl-max: $screen-xl - 1;
$screen-lg-max: $screen-lg - 1;
$screen-md-max: $screen-md - 1;
$screen-sm-max: $screen-sm - 1;

// Sizes
$font-size-base: 1.6rem;

$font-size-initial: 5.8rem; //58px;
$font-size-h1: 4.4rem; // 44px
$font-size-h2: 3.6rem; // 36px
$font-size-h3: 3rem; // 30px
$font-size-h4: 2.8rem; // 28px
$font-size-h5: 2.6rem; // 26px
$font-size-h6: 2.4rem; // 24px


$font-size-h1-a: 4.4rem; // 44px
$font-size-h2-a: 12rem; // 120px
$font-size-h3-a: 8rem; //80px
$font-size-h4-a: 6rem; // 60px
$font-size-h5-a: 4rem; // 40px
$font-size-h6-a: 2rem; // 20px

$font-size-h7-a: 2rem; // 20px
$font-size-h8-a: 1.8rem; // 18px

$font-size-subtitle:1rem; //10px


$font-size-ads: 1.2rem; //12px

// Letter spacings
$font-size-h1-word-spacing: 6rem;
$font-size-h4-letter-spacing: .1rem;

//line height
$line-height-base:1.2;
$line-height-title:1.4;
$line-height-t0:1;
$line-height-t2:0.6;



// Fonts
$font-helvetica:'Helvetica Neue, Helvetica, Arial, sans-serif';
$font-TradeGothic: TradeGothic;
$font-TradeGothicTwo: TradeGothic;
$font-proximanova-regular:proximanova-regular;
$font-proximanova-thin:proximanova-thin;

$weight-extra-light: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-bold: 600;
$weight-extra-bold: 800;

$primary-font-family: $font-helvetica;
$primary-font-size: $font-size-base;
$primary-weight: $weight-normal;
$primary-letter-spacing: 0rem;
$primary-word-spacing:  0rem;

$secondary-font-family: $font-TradeGothic;
$secondary-font-size: $font-size-base;
$secondary-weight: $weight-normal;
$secondary-letter-spacing: 0rem;
$secondary-word-spacing:  0rem;